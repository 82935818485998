body {
  background: #f7f7f7;
}

.text.center {
  text-align: center !important;
}

.field-error {
  color: #881d1d;
}

.input.search input {
  border-radius: 25px !important;
}
